export default {
    PRICE_LEVELS: [
        {
            level: undefined,
            text: "ANY"
        },
        {
            level: 10,
            text: 'Over $10'
        },
        {
            level: 20,
            text: 'Over $20'
        },
        {
            level: 30,
            text: 'Over $30'
        },
        {
            level: 40,
            text: 'Over $40'
        }
    ]
}