<template>
    <div class="table">
        <custom-table server-endpoint="api/purchases/get" @open-popup="openPurchasePopup" :page-size="20" :columns="columns" initial-sort-field="purchase_time" />
        <purchase-popup :popup-open="purchasePopupOpen" :content="{ ...popupContent }" @popup-close="purchasePopupOpen = false" />
    </div>
</template>

<script>
import Table from "@/components/TableResponsivePurch";
import PurchasePopup from "@/components/PurchasePopup"    ;
import { ref } from '@vue/reactivity';

export default {
  name: "index",
  components: {
    "custom-table": Table,
    "purchase-popup": PurchasePopup
  },
  setup() {
      
        const purchasePopupOpen = ref(false);
        const popupContent = ref({})

        const openPurchasePopup = content => {
            popupContent.value = content;
            purchasePopupOpen.value = true;
        }

        const columns = [
        {
            text: "User ID",
            field: "user_id",
            filterable: true
        },
        {
            text: "Product ID",
            field: "product_id",
            filterable: true
        },
        {
            text: "Price",
            field: "usd_price",
            type: "number",
            filterable: true
        },
        {
            text: "Client OS",
            field: "client_os",
            filterable: true
        },
        {
            text: "Client Region",
            field: "client_region",
            filterable: true
        },
        {
            text: "Purchase Date",
            field: "purchase_time",
            type: "date",
            filterable: true
        }
    ];
    return {
      columns,
      purchasePopupOpen,
      openPurchasePopup,
      popupContent
    }
  }
}

</script>

<style scoped>
.table {
  flex-grow: 1;
  max-width: 85vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 15px 5%;
}
</style>
