<template>
  <div>
      <div class="popup-wrapper" :style="!popupOpen ? { display: 'none'} : {}">
    <div class="popup-container">
      <div class="popup-header">
        <div class="popup-title">
          PURCHASE DETAILS
        </div>
        <a class="popup-button button-close" @click="$emit('popup-close')">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <div class="popup-outer-container">
          <div class="popup-content">
        <div class="form-container">
          <h4>{{ "User ID" }}</h4>
          <p>{{state.user_id}}</p>
        </div>
        <div class="form-container">
          <h4>{{ "Product ID" }}</h4>
          <p>{{state.product_id}}</p>
        </div>
        <div class="form-container-inline form-container">
          <h4>{{ "Price" }}</h4>
          <p>{{state.usd_price}}</p>
        </div>
        <div class="form-container-inline form-container">
          <h4>{{ "Client OS" }}</h4>
          <p>{{state.client_os}}</p>
        </div>
        <div class="form-container-inline form-container">
          <h4>{{ "Client Region" }}</h4>
          <p>{{state.client_region}}</p>
        </div>
        <div class="form-container-inline form-container">
          <h4>{{ "Date" }}</h4>
          <p>{{moment(state.purchase_time).format('DD/MM/YYYY HH:mm')}}</p>
        </div>
    
      </div>
      </div>
      <div class="popup-footer">
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { reactive, watch } from "vue";
import moment from "moment";

export default {
  name: "PurchasePopup",
  props: ["content", "popupOpen"],
  emits: ["popup-close"],
  setup(props) {

    const state = reactive({
        user_id: "",
        product_id: "",
        usd_price: 0,
        client_os: "",
        client_region: "",
        purchase_time: 0,
    });

    watch(() => props.content, value => {
        state.user_id = value.user_id;
        state.product_id = value.product_id;
        state.usd_price = value.usd_price;
        state.client_os = value.client_os;
        state.client_region = value.client_region;
        state.purchase_time = value.purchase_time;
    });

    return {
      state,
      moment
    }
  }
}
</script>

<style scoped>
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background-color: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-container-old {
  width: 70%;
  height: 60%;
  border-radius: 25px;
  background-color: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.popup-outer-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: scroll;
}

.popup-container {
  width: 70%;
  height: 60%;
  border-radius: 15px;
  background-color: white;
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.popup-content {
  width: 100%;
  height: 90%;
  align-self: flex-start;
}
.popup-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.popup-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.popup-button {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 5px 15px;
}

.button-close {
  color: red;

}

.button-save {
  color: green;
}

.popup-title {
  font-size: 1.3rem;
  font-weight: bold;
}

.reward-table {
  width: 100%;
}
.row {
  width: 100%;
  display: grid;
}

.chest-row {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}
button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
</style>